import AssignmentIcon from "@mui/icons-material/Assignment";
import Face6Icon from "@mui/icons-material/Face6";
import HomeIcon from "@mui/icons-material/Home";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Paper,
} from "@mui/material";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTablet } from "./contexts/TabletContext";
import { auth } from "./firebase";
import LoginLogoutPage from "./pages/LoginLogout";
import PeopleRating from "./pages/PeopleRating";
import TaskCards from "./pages/TasksAssignment";
import AppTablet from "./AppTablet";

const App: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [value, setValue] = React.useState("home");
  const { isTablet } = useTablet();

  const onFullscreen = () => {};

  const renderContent = () => {
    switch (value) {
      case "home":
        return <PeopleRating />;
      case "assignment":
        return <TaskCards />;
      case "login":
        return <LoginLogoutPage onFullscreen={onFullscreen} />;
      default:
        return null;
    }
  };

  if (isTablet) return <AppTablet />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
        maxWidth: isTablet ? "500px" : "100%",
        margin: isTablet ? "0 auto" : "0",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          flex: 1,
        }}
      >
        {renderContent()}
      </Box>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            console.log(newValue);
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Hodnoceni"
            icon={<HomeIcon />}
            value="home"
          />
          <BottomNavigationAction
            label="Ukoly"
            icon={<AssignmentIcon />}
            value="assignment"
          />
          <BottomNavigationAction
            label={user?.displayName ? user?.displayName : "Login"}
            icon={<Face6Icon />}
            value="login"
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default App;
