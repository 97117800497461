import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import PlayComponent from "./PlayComponent";
import DeviceCard, { DeviceData } from "./DeviceCard";

const PercentageDisplay: React.FC = () => {
  const [iframeKey, setIframeKey] = useState(0);

  useEffect(() => {
    // Set up interval to reload iframe every 15 minutes
    const interval = setInterval(() => {
      setIframeKey((prev) => prev + 1);
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const [liveData, liveDataLoading, liveDataError] = useDocumentData(
    doc(firestore, "live_data", "kmochovi")
  );

  if (liveDataLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (liveDataError) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography color="error">Error loading data.</Typography>
      </Box>
    );
  }

  // If the document doesn't exist or has no devices
  if (!liveData || !liveData.devices) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography>No device data available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {liveData.devices.map((device: DeviceData) => (
          <Grid item xs={12} sm={6} md={4} key={device.id}>
            <DeviceCard device={{ ...device, soundFile: "mySound.mp3" }} />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} key="play">
          <PlayComponent />
        </Grid>
      </Grid>

      {/* Existing iFrame (Windy map) below or above the data cards */}
      <Box
        sx={{
          mt: 3,
          width: "50%",
          height: 420,
          border: "1px solid #ccc",
          overflow: "hidden",
        }}
      >
        <iframe
          key={iframeKey}
          title="weather-map"
          width="100%"
          height="420"
          src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=m/s&zoom=9&overlay=radar&product=radar&level=surface&lat=49.73&lon=14.485&detailLat=49.889&detailLon=14.485&detail=true&message=true"
          style={{ border: "none" }}
        ></iframe>
      </Box>
    </Box>
  );
};

export default PercentageDisplay;
