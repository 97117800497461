import { Button, Card, CardContent } from "@mui/material";
import React, { useRef } from "react";
//import mySound from "./mySound.mp3"; // If you placed the file in src and are importing it directly

function PlayComponent() {
  // Use a React ref to access the audio element
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const pauseSound = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  return (
    <Card>
      <CardContent>
        {/* Buttons to play and pause the sound */}
        <Button onClick={playSound}>Play Sound</Button>

        {/* The audio element with a ref */}
        <audio ref={audioRef} src="/mySound.mp3" />
      </CardContent>
    </Card>
  );
}

export default PlayComponent;
