import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";

export interface DeviceData {
  id: string;
  title: string;
  power: number;
  voltage: number;
  status: boolean;
  soundFile?: string;
  lastFinished?: string;
  lastStarted?: string;
  running: boolean;
}

export const DeviceCard: React.FC<{ device: DeviceData }> = ({ device }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [wasRunning, setWasRunning] = useState(false);
  const [, setTimeUpdate] = useState(0); // Force update every minute
  console.log(device);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeUpdate((prev) => prev + 1);
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, []);

  const formatTimeAgo = (timestamp?: string) => {
    if (!timestamp) return "--";
    const time = new Date(timestamp);
    const now = new Date();
    const diffInMinutes = Math.floor(
      (now.getTime() - time.getTime()) / (1000 * 60)
    );

    if (diffInMinutes < 60) {
      return `před ${diffInMinutes} min`;
    }
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `před ${hours}hod ${minutes}min`;
  };

  const isFinishedRecently = () => {
    if (!device.lastFinished) return false;
    const finishedTime = new Date(device.lastFinished);
    const now = new Date();
    const diffInMinutes =
      (now.getTime() - finishedTime.getTime()) / (1000 * 60);
    return diffInMinutes <= 15;
  };

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const pauseSound = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  // This effect will run whenever device.power changes
  useEffect(() => {
    if (!device.running && wasRunning) {
      // The device just turned on
      playSound();
    }
    // Update the wasRunning state based on the current power
    setWasRunning(device.running);
  }, [device.running, wasRunning]);

  return (
    <Card
      sx={{
        backgroundColor: device.running
          ? "#e8f5e9"
          : isFinishedRecently()
          ? "#ffebee"
          : "inherit",
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {device.title}
        </Typography>
        <Typography variant="body2">Spotřeba: {device.power} W</Typography>
        <Typography variant="body2">
          Spuštěno: {formatTimeAgo(device.lastStarted)}
        </Typography>
        <Typography variant="body2">
          Ukončeno: {formatTimeAgo(device.lastFinished)}
        </Typography>
        {/* <Typography variant="body2">
          Running: {device.running ? "Ano" : "Ne"}
        </Typography> */}
        {device.soundFile && (
          <>
            <audio ref={audioRef} src={device.soundFile} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
