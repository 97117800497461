import React, { createContext, useContext } from "react";

interface TabletContextType {
  isTablet: boolean;
}

const TabletContext = createContext<TabletContextType>({ isTablet: false });

export const TabletProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isTablet =
    new URLSearchParams(window.location.search).get("tablet") === "1";

  return (
    <TabletContext.Provider value={{ isTablet }}>
      {children}
    </TabletContext.Provider>
  );
};

export const useTablet = () => useContext(TabletContext);
