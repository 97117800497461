import AssignmentIcon from "@mui/icons-material/Assignment";
import Face6Icon from "@mui/icons-material/Face6";
import HomeIcon from "@mui/icons-material/Home";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Paper,
} from "@mui/material";
import React from "react";
import PercentageDisplay from "./components/PercentageDisplay";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTablet } from "./contexts/TabletContext";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { auth } from "./firebase";
import LoginLogoutPage from "./pages/LoginLogout";
import PeopleRating from "./pages/PeopleRating";
import TaskCards from "./pages/TasksAssignment";

const AppTablet: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [value, setValue] = React.useState("home");
  const { isTablet } = useTablet();

  const handle = useFullScreenHandle();
  const onFullscreen = () => {
    handle.enter();
  };

  return (
    <FullScreen handle={handle}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#ffffff",
          width: "100%",
        }}
      >
        {!handle.active ? (
          <Button onClick={onFullscreen}>Fullscreen</Button>
        ) : (
          ""
        )}
        <Box
          sx={{
            flexGrow: 1,
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: 1,
              borderRight: "1px solid #eee",
            }}
          >
            <PercentageDisplay />
          </Box>
          <Box
            sx={{
              width: "400px",
            }}
          >
            <PeopleRating />
          </Box>
        </Box>
      </Box>
    </FullScreen>
  );
};

export default AppTablet;
